var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-expand-transition',[(_vm.message.files.length > 0)?_c('div',[_c('v-slide-group',{staticClass:"py-1",attrs:{"show-arrows":""}},_vm._l((_vm.filesSrc),function(f,i){return _c('v-slide-item',{staticClass:"pa-1"},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"d-flex flex-column justify-space-between",attrs:{"img":f.src,"height":"150","width":"150"}},[_c('div',{staticClass:"d-flex"},[_c('v-spacer'),_c('v-btn',{attrs:{"icon":"","color":"error"},on:{"click":[function($event){if(!$event.ctrlKey){ return null; }$event.preventDefault();_vm.message.files = []},function($event){$event.preventDefault();return _vm.message.files.splice(i, 1)}]}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('div',{staticClass:"d-flex justify-center"},[_c('v-chip',{attrs:{"small":"","label":""}},[_vm._v(_vm._s(f.name))])],1)])]}}],null,true)})],1)}),1),_c('v-divider')],1):_vm._e()]),_c('div',{staticClass:"d-flex pa-2",staticStyle:{"gap":"10px"}},[_c('v-btn',{attrs:{"fab":"","small":""},on:{"click":function($event){$event.stopPropagation();_vm.message.showFiles = true}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-paperclip")])],1),(_vm.$root.acceso('CONTROL_IMPAGADOS'))?_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","small":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":_vm.message.tipo ? _vm.getSituacionIcon(_vm.message.tipo).color : 'error'},domProps:{"textContent":_vm._s(
              _vm.message.tipo ? _vm.getSituacionIcon(_vm.message.tipo).icon : 'mdi-help'
            )}})],1)]}}],null,false,2137813109)},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item-group',{attrs:{"mandatory":""},model:{value:(_vm.message.tipo),callback:function ($$v) {_vm.$set(_vm.message, "tipo", $$v)},expression:"message.tipo"}},_vm._l(([
              'Llamada',
              'Email',
              'Justificante',
              'Comercial',
              'Cesce',
              'Otros' ]),function(tipo){return _c('v-list-item',{key:tipo,attrs:{"value":tipo}},[_c('v-list-item-icon',[_c('v-icon',{domProps:{"textContent":_vm._s(_vm.getSituacionIcon(tipo).icon)}})],1),_vm._v(" "+_vm._s(tipo)+" ")],1)}),1)],1)],1):_vm._e(),_c('v-text-field',{attrs:{"filled":"","auto-grow":"","rows":"1","hide-details":"","dense":"","placeholder":"Escribe aquí tu mensaje..."},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.send($event)},"paste":_vm.pasteHandler},model:{value:(_vm.message.msg),callback:function ($$v) {_vm.$set(_vm.message, "msg", $$v)},expression:"message.msg"}}),_c('v-btn',{attrs:{"fab":"","small":"","color":"primary","disabled":(_vm.message.msg == '' && _vm.message.files.length == 0) ||
          !_vm.$root.acceso('CONTROL_IMPAGADOS') ||
          _vm.message.tipo == null},on:{"click":function($event){$event.stopPropagation();return _vm.send($event)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-send")])],1)],1),_c('v-dialog',{attrs:{"max-width":"600px"},model:{value:(_vm.message.showFiles),callback:function ($$v) {_vm.$set(_vm.message, "showFiles", $$v)},expression:"message.showFiles"}},[_c('v-card',[_c('div',{staticClass:"pa-2"},[_c('v-subheader',[_vm._v("Adjuntar archivos")]),_c('DragAndDrop',{attrs:{"multiple":""},model:{value:(_vm.message.files),callback:function ($$v) {_vm.$set(_vm.message, "files", $$v)},expression:"message.files"}}),_c('v-chip',{staticClass:"mt-2",attrs:{"small":"","label":""}},[_vm._v(_vm._s(_vm.message.files.length)+" adjuntos")])],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"error","text":""},on:{"click":function($event){$event.stopPropagation();_vm.message.showFiles = false;
            _vm.message.files = [];}}},[_vm._v(" Cancelar ")]),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){$event.stopPropagation();_vm.message.showFiles = false}}},[_vm._v(" Aceptar ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }