<template>
  <div>

    <v-expand-transition>
      <div v-if="message.files.length > 0">
        <v-slide-group class="py-1" show-arrows>
          <v-slide-item v-for="(f, i) in filesSrc" class="pa-1">
            <v-hover v-slot="{ hover }">
              <v-card :img="f.src" height="150" width="150" class="d-flex flex-column justify-space-between">
                <div class="d-flex">
                  <v-spacer />
                  <v-btn icon color="error" @click.ctrl.prevent=" message.files = [] " @click.prevent=" message.files.splice(i, 1) " >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </div>
                <div class="d-flex justify-center">
                  <v-chip small label>{{ f.name }}</v-chip>
                </div>
              </v-card>
            </v-hover>
          </v-slide-item>
        </v-slide-group>

        <v-divider />
      </div>
    </v-expand-transition>

    <div class="d-flex pa-2" style="gap: 10px">
      <v-btn fab small @click.stop="message.showFiles = true">
        <v-icon small>mdi-paperclip</v-icon>
      </v-btn>

      <v-menu v-if="$root.acceso('CONTROL_IMPAGADOS')">
        <template v-slot:activator="{ on, attrs }">
          <v-btn fab small v-on="on" v-bind="attrs">
            <v-icon
              :color="
                message.tipo ? getSituacionIcon(message.tipo).color : 'error'
              "
              v-text="
                message.tipo ? getSituacionIcon(message.tipo).icon : 'mdi-help'
              "
            />
          </v-btn>
        </template>
        <v-list dense>
          <v-list-item-group mandatory v-model="message.tipo">
            <v-list-item
              v-for="tipo in [
                'Llamada',
                'Email',
                'Justificante',
                'Comercial',
                'Cesce',
                'Otros',
              ]"
              :value="tipo"
              :key="tipo"
            >
              <v-list-item-icon>
                <v-icon v-text="getSituacionIcon(tipo).icon" />
              </v-list-item-icon>
              {{ tipo }}
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-menu>

      <v-text-field
        v-model="message.msg"
        filled
        auto-grow
        rows="1"
        hide-details
        dense
        placeholder="Escribe aquí tu mensaje..."
        @keydown.enter="send"
        @paste="pasteHandler"
      >
      </v-text-field>

      <v-btn
        fab
        small
        color="primary"
        @click.stop="send"
        :disabled="
          (message.msg == '' && message.files.length == 0) ||
            !$root.acceso('CONTROL_IMPAGADOS') ||
            message.tipo == null
        "
      >
        <v-icon small>mdi-send</v-icon>
      </v-btn>
    </div>

    <v-dialog max-width="600px" v-model="message.showFiles">
      <v-card>
        <div class="pa-2">
          <v-subheader>Adjuntar archivos</v-subheader>
          <DragAndDrop multiple v-model="message.files" />
          <v-chip class="mt-2" small label
            >{{ message.files.length }} adjuntos</v-chip
          >
        </div>

        <v-card-actions>
          <v-spacer />
          <v-btn
            color="error"
            text
            @click.stop="
              message.showFiles = false;
              message.files = [];
            "
          >
            Cancelar
          </v-btn>
          <v-btn color="primary" @click.stop="message.showFiles = false">
            Aceptar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { getSituacionIcon } from "@/utils/index.js";

export default {
  props: {
    value: Object,
    keyName: String,
    itemKey: String | Number,
  },
  components: {
    TextEditor: () => import("@/components/TextEditor.vue"),
    DragAndDrop: () => import("@/components/DragAndDrop.vue"),
  },
  data() {
    return {
      message: {
        showFiles: false,
        msg: "",
        files: [],
        tipo: null,
      },
    };
  },
  methods: {
    getSituacionIcon,
    send() {
      const formData = new FormData();
      if (!this.message.msg && this.message.files.length == 0) return;
      if (this.message.tipo == null && this.$root.acceso("CONTROL_IMPAGADOS")) {
        this.$root.$emit("snack", "Tienes que seleccionar el tipo");
        return;
      }
      formData.append("token", this.$store.getters.getJwtEmpresa);
      formData.append(this.keyName, this.itemKey);
      let msg = this.message.msg;
      msg = msg.replace(
        /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig,
        (url) => {
          if (!/^https?:\/\//i.test(url)) url = "https://" + url;
          return `<a target="_blank" href="${url}">${url}</a>`;
        }
      );
      msg = msg.replace(/\r?\n/g, "<br />");

      formData.append("mensaje", msg);
      formData.append("tipo", this.message.tipo || "otros");
      for (let i = 0; i < this.message.files.length; i++) {
        formData.append("documentos[]", this.message.files[i]);
      }
      axios({
        method: "POST",
        url: `${process.env.VUE_APP_OUR_API_URL}/mensajesImpagados.php`,
        data: formData,
      })
        .then(({ data }) => {
          this.cancelar();
          this.$emit("reload");
        })
        .catch((err) => console.error(err));
    },
    cancelar() {
      this.message.showFiles = false;
      this.message.msg = "";
      this.message.files = [];
      this.message.tipo = null;
    },
    pasteHandler(ev) {
      const _Files = [];
      if (ev.clipboardData.items) {
        // Use clipboardDataItemList interface to access the file(s)
        for (var i = 0; i < ev.clipboardData.items.length; i++) {
          // If dropped items aren't files, reject them
          if (ev.clipboardData.items[i].kind === "file") {
            var file = ev.clipboardData.items[i].getAsFile();
            _Files.push(file);
          }
        }
      } else {
        // Use clipboardData interface to access the file(s)
        for (var i = 0; i < ev.clipboardData.files.length; i++) {
          _Files.push(files[i]);
        }
      }

      this.message.files = [...this.message.files, ..._Files];
      // console.log(e.clipboardData)
      // const files = e.clipboardData.items
      // const selectedFiles = []
      // for (var i = 0; i < files.length; i++) {
      //   selectedFiles.push(files[i]);
      // }
      // console.log(selectedFiles)
    },
    imgTo64(file) {
      console.log('osdjfdisjf');
      if ( file && file['type'].split('/')[0] === 'image' );
        return URL.createObjectURL(file)
      return null
    },
  },
  computed: {
    filesSrc() {
      return this.message.files.map(file => {
        return {
          name: file.name,
          src : this.imgTo64(file)
        }
      })
    }
  }
};
</script>
